import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
/*const Home = () => import('../views/home/Home');
const Category = () => import('../views/category/Category');
const Profile = () => import('../views/profile/Profile');
const ShopCart = () => import('../views/shopcart/ShopCart');

const Register = () => import('../views/profile/Register');
const Login = () => import('../views/profile/Login');
const Collect = () => import('../views/collect/Collect');

const Address = () => import('../views/profile/Address');
const PayTest = () => import('../views/paytest/PayTest');
const AddressEdit = () => import('../views/profile/AddressEdit');
const Order = () => import('../views/order/Order');
const OrderDetail = () => import('../views/order/OrderDetail');
const CreateOrder = () => import('../views/order/CreateOrder');
const About = () => import('../views/about/About');*/



const Detail1 = () => import('../views/detail/Detail1');
const Detail2 = () => import('../views/detail/Detail2');
const Detail3 = () => import('../views/detail/Detail3');
const Detail4 = () => import('../views/detail/Detail4');
const Detail5 = () => import('../views/detail/Detail5');
const Detail6 = () => import('../views/detail/Detail6');
const Detail7 = () => import('../views/detail/Detail7');
const Detail8 = () => import('../views/detail/Detail8');
const Detail9 = () => import('../views/detail/Detail9');
const Detail10 = () => import('../views/detail/Detail10.vue');
const Detail11 = () => import('../views/detail/Detail11.vue');
const Detail12 = () => import('../views/detail/Detail12.vue');
const Detail13 = () => import('../views/detail/Detail13.vue');
const Detail14 = () => import('../views/detail/Detail14.vue');
const Detail15 = () => import('../views/detail/Detail15.vue');
const Detail16 = () => import('../views/detail/Detail16.vue');
const Detail17 = () => import('../views/detail/Detail17.vue');
const Detail18 = () => import('../views/detail/Detail18.vue');
const Detail19 = () => import('../views/detail/Detail19.vue');
const Detail20 = () => import('../views/detail/Detail20.vue');
const Detail21 = () => import('../views/detail/Detail21.vue');
const Detail22 = () => import('../views/detail/Detail22.vue');










import store from '../store';
import {Notify, Toast} from "vant";

const routes = [
  /*{
    path: '/',
    alias:['/','/home'],
    name: 'Home',
    component: Home,
    meta:{
      title:'图书兄弟',
      keepAlive:true,
    }
  },

  {
    path: '/category',
    name: 'Category',
    component: Category,
    meta:{
      title:'图书兄弟-商品分类',
      keepAlive:true,
    }
  },
  {
    path: '/shopcart',
    name: 'ShopCart',
    component: ShopCart,
    meta:{
      title:'图书兄弟-购物车',
      isAuthRequired:true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta:{
      title:'图书兄弟-个人中心',
      isAuthRequired:true
    }
  },
  {
    path: '/collect',
    name: 'Collect',
    component: Collect,
    meta:{
      title:'我的收藏',
      isAuthRequired:true
    }
  },*/

  {
    path: '/detail1',
    name: 'Detail1',
    component: Detail1,
    meta:{
      title:'全国微留学-澳洲站',
      keepAlive:true,
    }
  },
  {
    path: '/detail2',
    name: 'Detail2',
    component: Detail2,
    meta:{
      title:'全国微留学-美国加州',
      keepAlive:true,
    }
  },
  {
    path: '/detail3',
    name: 'Detail3',
    component: Detail3,
    meta:{
      title:'全国微留学-新加坡',
      keepAlive:true,
    }
  },
  {
    path: '/detail4',
    name: 'Detail4',
    component: Detail4,
    meta:{
      title:'全国名校研学-新加坡',
      keepAlive:true,
    }
  },
  {
    path: '/detail5',
    name: 'Detail5',
    component: Detail5,
    meta:{
      title:'全国微留学-新西兰',
      keepAlive:true,
    }
  },
  {
    path: '/detail6',
    name: 'Detail6',
    component: Detail6,
    meta:{
      title:'全国微留学-英国站',
      keepAlive:true,
    }
  },
  {
    path: '/detail7',
    name: 'Detail7',
    component: Detail7,
    meta:{
      title:'亲子全球-澳洲站',
      keepAlive:true,
    }
  },
  {
    path: '/detail8',
    name: 'Detail8',
    component: Detail8,
    meta:{
      title:'全球名校体验-澳洲站',
      keepAlive:true,
    }
  },
  {
    path: '/detail9',
    name: 'Detail9',
    component: Detail9,
    meta:{
      title:'全球名校体验-美国站',
      keepAlive:true,
    }
  },
  {
    path: '/detail10',
    name: 'Detail10',
    component: Detail10,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail11',
    name: 'Detail11',
    component: Detail11,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail12',
    name: 'Detail12',
    component: Detail12,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail13',
    name: 'Detail13',
    component: Detail13,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail14',
    name: 'Detail14',
    component: Detail14,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail15',
    name: 'Detail15',
    component: Detail15,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail16',
    name: 'Detail16',
    component: Detail16,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail17',
    name: 'Detail17',
    component: Detail17,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail18',
    name: 'Detail18',
    component: Detail18,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail19',
    name: 'Detail19',
    component: Detail19,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail20',
    name: 'Detail20',
    component: Detail20,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail21',
    name: 'Detail21',
    component: Detail21,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },
  {
    path: '/detail22',
    name: 'Detail22',
    component: Detail22,
    meta:{
      title:'国际游学散拼计划',
      keepAlive:true,
    }
  },












]

const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
  history:createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next)=>{

  next();

  document.title = to.meta.title

})

export default router
